<template>
    <div class="py-5 container">
        <div>
            <h2 class="text-center fs-4 mb-0">Request Quotation</h2>
            <h4 class="text-center my-3"
            :class="{'text-success': !message.includes('Yikes'), 'text-danger': message.includes('Yikes')}">{{ message }}</h4>
            <form @submit.prevent="send_mail">
                <div class="px-3">
                    <div class="card-body d-flex flex-column gap-3">
                        <input v-model="mail.full_name" type="text" class="w-100 border-0 border-bottom py-2" placeholder="Full Name" required>
                        <div class="text-danger" v-show="Object.values(errors.full_name).length">
                            <ul v-for="error in errors.full_name" :key="error">
                                <li>
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                        <input v-model="mail.email" type="email" class="w-100 border-0 border-bottom py-2" placeholder="E-mail" required>
                        <div class="text-danger" v-show="Object.values(errors.email).length">
                            <ul v-for="error in errors.email" :key="error">
                                <li>
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                        <input v-model="mail.phone" type="number" class="w-100 border-0 border-bottom py-2" placeholder="Contact No." required>
                        <div class="text-danger" v-show="Object.values(errors.phone).length">
                            <ul v-for="error in errors.phone" :key="error">
                                <li>
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                        <h4 style="font-size: medium;">Items: </h4>
                        <p style="text-indent: 25px">
                            <ul v-for="item in mail.items.data" :key="item">
                                <li><strong>Name:</strong> {{ item.name }}</li>
                                <li><strong>Description:</strong> {{ item.description }}</li>
                                <li><strong>Price:</strong> {{ item.price }}</li>
                                <li><strong>Quantity:</strong> {{ item.quantity }}</li>
                            </ul>
                            <span>Total: {{ mail.items.total }}</span>
                        </p>
                        <textarea v-model="mail.mesg" type="text" class="w-100 border-0 border-bottom py-2" placeholder="Message" rows="1" required></textarea>
                        <div class="text-danger" v-show="Object.values(errors.mesg).length">
                            <ul v-for="error in errors.mesg" :key="error">
                                <li>
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="ms-auto mt-3">
                    <button type="submit" class="rounded-l-sm p-2 d-flex align-items-center gap-2 text-white bg-dark border-0 ms-auto">Send Message</button>
                </div>
            </form>
        </div>
        <div class="page-loader" v-show="$store.state.isLoading"></div>
    </div>
</template>

<style>
.page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.page-loader:before {
  content: "";
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid rgb(0, 0, 0, 0.2);
  border-top-color: #6E111D;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
    name: 'Quotation',
    data() {
        return {
            mail: {
                full_name: '',
                email: '',
                phone: '',
                mesg: '',
                items: {}
            },
            message: '',
            errors: {
                full_name: {},
                email: {},
                phone: {},
                mesg: {},
                items: {}
            }
        }
    },
    beforeMount() {
        if(!localStorage.getItem('to_request')) this.$router.push('/products')
    },
    mounted() {
        var total = 0
        const obj = JSON.parse(localStorage.getItem('to_request'))
        var new_obj = {}
        for (var key in obj) {
            new_obj[key] = {
                'name': obj[key].title,
                'description': obj[key].description,
                'price': obj[key].price,
                'quantity': obj[key].quantity 
            }
            total += obj[key].price * obj[key].quantity
        }

        this.mail.items['data'] = new_obj
        this.mail.items['total'] = total
    },
    methods: {
        async send_mail() {
            await this.$store.dispatch('send', this.mail).then(() => {
                if(Object.values(this.$store.state.errors).length) Object.assign(this.errors, this.$store.state.errors)
                this.message = this.$store.state.message
                if(this.message.includes('Yikes')) return
                else {
                    localStorage.removeItem('to_request')
                    setTimeout(() => this.$router.push('/products'), 2000)
                }
            })
        }
    }
}
</script>